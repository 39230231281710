


import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import {Action, Getter} from 'vuex-class';
import DateTimeHelper from '@/_helpers/date-time.helper';
import { TUser} from '@/_types/user.type';
import { TEvent} from '@/_types/event.type';
import MeetingsRequests from '@/_modules/meetings/components/meetings-requests/meetings-requests.vue';
import { TEventDay, TMeeting, TTimeSlot } from '@/_types/meeting/meeting.type';
import MeetingsHelper from '@/_helpers/meetings.helper';

import IconMeetingAccept from '@/_modules/icons/components/meetings/icon-meeting-accept.vue';
import IconMeetingPlus from '@/_modules/icons/components/meetings/icon-meeting-plus.vue';
import IconMeetingReject from '@/_modules/icons/components/meetings/icon-meeting-reject.vue';
import IconMeetingShare from '@/_modules/icons/components/meetings/icon-meeting-share.vue';
import IconMeetingStar from '@/_modules/icons/components/meetings/icon-meeting-star.vue';
import IconMeetingStart from '@/_modules/icons/components/meetings/icon-meeting-start.vue';
import AddToCalendar from '@/_components/add-to-calendar/add-to-calendar.vue';
import { TContact } from '@/_types/contact.type';
import { MeetingStatus } from '@/_modules/meeting-rooms/types/meeting-status.enum';
import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
import { TranslateResult } from 'vue-i18n';

import MeetingsHead from '@/_modules/meetings/components/meetings-head/meetings-head.vue';
import { TNotification } from '@/_modules/promo/types/notification.type';
import eventDiscoveryService from '@/_services/event-discovery.service';
import {TOpenEwSharerPayload} from '@/_store/ew-sharer.store';

@Component({
  components: {
    MeetingsRequests,
    AddToCalendar,
    IconMeetingAccept,
    IconMeetingPlus,
    IconMeetingReject,
    IconMeetingShare,
    IconMeetingStar,
    IconMeetingStart,
    MeetingsHead
  },
  computed: {
    ...mapGetters({
      user: '_userStore/user',
      event: '_eventStore/event',
      contact: 'promoPageStore/contact',
      meetingsCount: 'notificationsStore/meetingsCount',
      noticedMeetingsCount: 'notificationsStore/noticedMeetingsCount',
      getMeetingsByUserId: 'meetingsStore/getMeetingsByUserId',
      getSelectedDate: 'meetingsStore/getMeetingsSelectedDate',
      notifications: 'notificationsStore/popupNotifications',
      isMeetingCancel: 'meetingsStore/isMeetingCancel',
    }),
    ...mapState('meetingsStore', {
      meetingsByUserId: 'meetingsByUserId'
    })
  }
})

export default class Meetings extends Vue {

  @Action('ewSharerStore/openSharer') openSharer: (payload: TOpenEwSharerPayload) => void;
  @Action('ewSharerStore/closeSharer') closeSharer: () => void;
  @Action('contactsStore/openContactCard') openContactCard: (params: { contactId: number; startupTabName: string }) => void;
  @Getter('ewSharerStore/urlToShare') urlToShare: string;

  public readonly user: TUser;
  public readonly event: TEvent;
  public readonly getMeetingsByUserId: Function;
  public getSelectedDate: TEventDay;
  public readonly contact: TContact;
  public eventDays: TEventDay[] = [];
  public readonly meetingsByUserId: TMeeting[];
  public timeSlots: TTimeSlot[] = [];
  public readonly notifications: TNotification[];
  public readonly noticedMeetingsCount: TNotification[];
  public month: number = null;
  public week: number = null;
  public nowTimestamp: number = (new Date()).getTime();
  public nowTimestampUpdaterIntervalId: number = null;
  public readonly meetingsCount: number;
  public isMeetingCancel: false;
  public tempEvent: Event;
  public tempMeeting: TMeeting;
  public tempTimeSlot: TTimeSlot;

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get externalId(): string {
    return this.$route.params.external_id ? this.$route.params.external_id : null;
  }

  public get contactId(): number {
    return (this.contact && this.contact.id) || null;
  }

  public get getConfirmedMeetings(): TMeeting[] {
    if (!this.user) {
      return [];
    }

    return this.getMeetingsByUserId(this.userId).filter((item: TMeeting) => {
      return item.status === MeetingStatus.Confirmed;
    });
  }

  public get selectedDate(): TEventDay {
    return this.getSelectedDate;
  }

  public get selectedDayMeetingList(): { [key: string]: TMeeting } {
    if (this.selectedDate && !this.selectedDate.date) {
      return {};
    }
    const confirmedMeetings = (this.getConfirmedMeetings || []);
    const filterDate = DateTimeHelper.getDateWithoutHoursMinutes(this.selectedDate.date);
    if (!confirmedMeetings.length) {
      return {};
    }

    return confirmedMeetings.filter((item: TMeeting) => {
      const dateStart = DateTimeHelper.getDateWithoutHoursMinutes(new Date(item.date_start));

      return dateStart === filterDate;
    }).reduce((acc: { [key: string]: TMeeting }, item: TMeeting) => {
      acc[DateTimeHelper.getFullDate(new Date(item.date_start))] = item;
      return acc;
    }, {});
  }

  public get userId(): number {
    return (this.user && this.user.id) || null;
  }

  public get timeSlotsPastTimeMark(): number {
    // Current time - 30 minutes, as specified in AW-1779
    // See isTimeSlotPast for comparison
    return this.nowTimestamp - (1000 * 60 * 30);
  }

  public set timeSlotsPastTimeMark(value: number) {
    this.nowTimestamp = value;
  }

  @Watch('event', {immediate: true})
  private onEventChanged(): void {
    if (!this.event) {
      return;
    }
    this.getEventDays();
    this.createTimeSlots();
  }

  @Watch('user', {immediate: true})
  private onUserChanged(): void {
    if (!this.userId) {
      return; // Avoid requesting a 404 with null as parameter
    }
    this.$store.dispatch('meetingsStore/requestUserMeetings', {userId: this.userId, force: true});
  }

  @Watch('meetingsByUserId', {immediate: true})
  private onMeetingsChanged(): void {
    this.createTimeSlots();
  }

  @Watch('selectedDate')
  private onSelectedDateChanged(): void {
    this.createTimeSlots();
  }

  @Watch('meetingsCount')
  private onMeetingsCountChanged(): void {
    if (!this.user) {
      return;
    }
    this.$store.dispatch('meetingsStore/requestUserMeetings', {
      userId: this.userId,
      force: true,
    });
    this.createTimeSlots();
  }

  @Watch('isMeetingCancel')
  private onCancelMeetingChanged(): void {
    if(this.isMeetingCancel) {
      this.cancelMeeting(this.tempEvent, this.tempMeeting, this.tempTimeSlot);
    }
  }

  public mounted(): void {
    this.startNowTimestampUpdate();
  }

  public beforeDestroy(): void {
    this.stopNowTimestampUpdate();
  }

  private startNowTimestampUpdate(): void {
    this.nowTimestampUpdaterIntervalId = window.setInterval(() => {
      this.timeSlotsPastTimeMark = (new Date()).getTime();
    }, 1000 * 60);
  }

  private stopNowTimestampUpdate(): void {
    window.clearInterval(this.nowTimestampUpdaterIntervalId);
  }

  private isTimeSlotMeetingCurrent(timeSlot: TTimeSlot): boolean {
    if (!timeSlot.meeting) {
      return false;
    }

    return timeSlot.meeting.date_start.getTime() < this.nowTimestamp
      && timeSlot.meeting.date_end.getTime() > this.nowTimestamp;
  }

  public getEventDays(): void {
    if (!this.event) {
      return;
    }
    this.eventDays = [];

    const day = 60 * 60 * 24 * 1000;
    const dateStart = this.event.date_start;
    const dateEnd = this.event.date_end;
    let iteratedDate = dateStart;
    const currentMoment = this.$moment();

    for (let i = 0; i <= DateTimeHelper.getDateDiff(new Date(dateEnd), new Date(dateStart)); i++) {
      const eventDay: TEventDay = {
        month: iteratedDate.getMonth() + 1,
        monthName: this.$moment(iteratedDate).format('MMMM'),
        week: this.$moment(iteratedDate).week(),
        date: iteratedDate,
        dayNumber: this.$moment(iteratedDate).format('D').padStart(2, '0'),
        badgeNotification: false
      };

      this.eventDays = [...this.eventDays, eventDay];

      if (currentMoment.isSame(iteratedDate, 'day')) {
        this.$store.dispatch('meetingsStore/meetingsScheduleDate', eventDay);
      }

      iteratedDate = new Date(iteratedDate.getTime() + day);
    }

    if (!this.selectedDate.date) {
      this.$store.dispatch('meetingsStore/meetingsScheduleDate', this.eventDays[0]);
    }
  }

  // slots
  public setSlotTime(dateValue: Date, hours: number, minutes: number, seconds: number): Date {
    const date = new Date(dateValue);
    date.setHours(hours, minutes, seconds);
    return date;
  }

  public addMinutes(date: Date, minutes: number): Date {
    return new Date(date.getTime() + minutes * 60000);
  }

  public createTimeSlots(): void {
    this.timeSlots = [];
    const event = this.event;
    if (
      !this.selectedDate
      || !this.selectedDate.date
      || !event.date_start
      || !event.date_end
    ) {
      return;
    }

    const eventDateStartMoment = this.$moment(event.date_start);
    const eventDateEndMoment = this.$moment(event.date_end);
    if (eventDateStartMoment.isSameOrAfter(eventDateEndMoment)) {
      return;
    }

    const selectedDayMeetingList = this.selectedDayMeetingList;
    const selectedDateMoment = this.$moment(this.selectedDate.date);
    let iterateTimeMoment = selectedDateMoment.clone()
      .hours(0).minutes(0).seconds(0).milliseconds(0);
    const endTimeMoment = iterateTimeMoment.clone().add(1, 'day');
    const uniqueDates: { [dateKey: string]: boolean } = {};

    while (iterateTimeMoment.isBefore(endTimeMoment)) {
      const currentTimeDateKey = DateTimeHelper.getFullDate(iterateTimeMoment.toDate());
      const nextTimeMoment = iterateTimeMoment.clone().add(30, 'minutes');
      if (
        iterateTimeMoment.isBefore(eventDateStartMoment)
        || !!uniqueDates[currentTimeDateKey]
      ) {
        iterateTimeMoment = nextTimeMoment;
        continue;
      } else if (nextTimeMoment.isAfter(eventDateEndMoment)) {
        break;
      }

      this.timeSlots.push({
        dateStart: iterateTimeMoment.toDate(),
        dateEnd: nextTimeMoment.toDate(),
        meeting: selectedDayMeetingList[currentTimeDateKey] || null,
      });

      uniqueDates[currentTimeDateKey] = true;
      iterateTimeMoment = nextTimeMoment;
    }
  }

  public async markSlotNotAvailable(timeSlot: TTimeSlot): Promise<void> {
    const dateStart = timeSlot.dateStart;
    const dateEnd = timeSlot.dateEnd;

    const response = await this.$store.dispatch('meetingsStore/requestMeeting', {
      event_id: this.eventId,
      user_id: this.userId,
      date_start: DateTimeHelper.dateToApiDate(dateStart),
      date_end: DateTimeHelper.dateToApiDate(dateEnd),
    });

    if (response.status !== 200 || response.error) {
      timeSlot.errors = timeSlot.errors || [];
      timeSlot.errors = [...timeSlot.errors, {text: response.error ? response.error : this.$t('errors.meetingGeneralError')}];
      const timeSlotIndex = this.timeSlots.indexOf(timeSlot);
      this.timeSlots[timeSlotIndex] = { ...timeSlot };
      this.timeSlots = [ ...this.timeSlots ];
      return;
    }

    if (response && response.data && response.data.status === MeetingStatus.Canceled) {
      timeSlot.meeting = response.data;
    }
  }

  public clearMeetingErrors(timeSlot: TTimeSlot, index: number): void{
    timeSlot.errors = [];
    this.timeSlots[index] = { ...timeSlot };
    this.timeSlots = [ ...this.timeSlots ];
  }

  public async markSlotAvailable(timeSlot: TTimeSlot): Promise<void> {
    if (timeSlot.meeting && timeSlot.meeting.id) {
      await this.$store.dispatch('meetingsStore/cancelMeeting', {
        event_id: this.eventId,
        meeting_id: timeSlot.meeting.id,
      });
      timeSlot.meeting = null;
    }
  }

  public isTimeSlotAvailable(timeSlot: TTimeSlot): boolean {
    return !(timeSlot && timeSlot.meeting);
  }

  public isTimeSlotPast(timeSlot: TTimeSlot): boolean {
    return timeSlot.dateEnd.getTime() < this.timeSlotsPastTimeMark;
  }

  public isTimeSlotBusy(timeSlot: TTimeSlot): boolean {
    return !!(timeSlot && timeSlot.meeting);
  }

  public isTimeSlotDisabled(timeSlot: TTimeSlot): boolean {
    return (timeSlot.meeting && timeSlot.meeting.contact.user.id === this.userId);
  }

  public getAddToCalendarStart(meeting: TMeeting): Date {
    return meeting.date_start;
  }

  public getAddToCalendarEnd(meeting: TMeeting): Date {
    return meeting.date_end;
  }

  public getAddToCalendarTitle(meeting: TMeeting): TranslateResult {
    const contactId = this.contactId;
    if (meeting.creator_contact.id !== contactId) {
      return this.$t('[\'add-to-calendar\'].meeting.title', {
        name: (meeting.creator_contact && meeting.creator_contact.fullName) || 'noname',
      });
    } else {
      return this.$t('[\'add-to-calendar\'].meeting.title', {
        name: (meeting.user_contact && meeting.user_contact.fullName) || 'noname',
      });
    }
  }

  public getAddToCalendarDetails(meeting: TMeeting): TranslateResult {
    return this.$t('[\'add-to-calendar\'].meeting.details', {
      link: this.getMeetingShareUrl(meeting),
    });
  }

  public getMeetingShareUrl(meeting: TMeeting): string {
    return MeetingsHelper.getMeetingInviteUrl({
      type: MeetingRoomType.MEETING,
      eventId: this.eventId,
      meetingId: meeting.id,
      meetingDate: this.$moment(meeting.date_start).unix(),
    });
  }

  public messageContact(meeting: TMeeting): void {
    const targetRouteName = 'promo-page-calendar-contact';
    const contactId = meeting.contact.id;

    // avoiding redundant navigation
    if (this.$route.name === targetRouteName && parseInt(this.$route.params.contact_id, 10) === contactId) {
      return;
    }

    this.openContactCard({
      contactId,
      startupTabName: 'messages',
    });
  }

  public onHandshakeClick(meeting: TMeeting): void {
    const nowTimestamp = (new Date()).getTime();
    if (meeting.date_start.getTime() - nowTimestamp > (1000 * 60 * 5)) {
      this.$store.dispatch('meetingsStore/setMeetingTooEarlyPopupVisible', true);
      return;
    }
    const meetingRoomConfig = {
      type: 'meeting',
      eventId: this.eventId,
      meetingId: meeting.id,
      contactId: this.contactId,
      meetingDate: this.$moment(meeting.date_start).unix(),
    };
    this.$store.dispatch('meetingRoomsStore/join', meetingRoomConfig);
    this.dispatchWaitingMeetingNotification(meeting);
  }

  public dispatchWaitingMeetingNotification(meeting: TMeeting): void {
    if (meeting && meeting.contact && meeting.contact.id) {
      const context: any = {
        type: 'meeting-is-waiting',
        eventId: this.eventId,
        meetingId: meeting.id || undefined,
        meetingDate: this.$moment(meeting.date_start).unix(),
        moderatorContactId: this.contactId || undefined,
        externalId: this.externalId || undefined,
      };
      eventDiscoveryService.notifyAboutWaitingMeeting(meeting.contact.id, context);
    }
  }

  public onMeetingShareClick(event: PointerEvent, meeting: TMeeting): void {
    const newUrlToShare: string = this.getMeetingShareUrl(meeting);
    if (this.urlToShare === newUrlToShare) {
      this.closeSharer();
      return;
    }
    this.openSharer({
      eventTarget: event.target as Element,
      url: newUrlToShare,
    });
  }

  public showCancelConfirmation(event: Event, meeting: TMeeting, timesSlot: TTimeSlot): void {
    this.tempEvent = event;
    this.tempMeeting = meeting;
    this.tempTimeSlot = timesSlot;
    this.$store.dispatch('meetingsStore/setMeetingCancelPopupVisible', true);
  }

  public async cancelMeeting(event: Event, meeting: TMeeting, timeSlot: TTimeSlot): Promise<void> {
    if (!(meeting && meeting.id)) {
      await this.$store.dispatch('meetingsStore/setMeetingCancelPopupVisible', false);
      await this.$store.dispatch('meetingsStore/setMeetingCancel', false);
      return;
    }

    const initialMeetingStatus = meeting.status;

    let meetingElement: HTMLElement = event.target as HTMLElement;
    while (meetingElement.tagName.toLowerCase() !== 'body') {
      if (meetingElement.classList.contains('time-slot')) {
        break;
      }
      meetingElement = meetingElement.parentNode as HTMLElement;
    }

    if (meetingElement.tagName.toLowerCase() === 'body') {
      return;
    }

    meetingElement.classList.add('time-slot-processing');

    const cancelMeetingResponse = await this.$store.dispatch('meetingsStore/cancelMeeting', {
      event_id: this.eventId,
      meeting_id: meeting.id
    });

    meetingElement.classList.remove('time-slot-processing');

    // Backend error displaying
    // If response is undefined, or response.status !== 202 or if error field is present, show:
    // — response.error as is  OR
    // — default message as is
    if (!cancelMeetingResponse || cancelMeetingResponse.status !== 202 || cancelMeetingResponse.error) {
      meeting.errors = meeting.errors || [];
      meeting.errors.push({
        text: cancelMeetingResponse.error ? cancelMeetingResponse.error : this.$t('errors.meetingGeneralError'),
      });
      return;
    }

    if (cancelMeetingResponse.status && cancelMeetingResponse.status === 202) {
      if (timeSlot) {
        timeSlot.meeting = null;
      }

      // Визуальный фидбек: удалить элемент из списка, повлиять на красный кружочек у соотв. дня
      if (initialMeetingStatus === MeetingStatus.Unconfirmed) {
        meetingElement.parentNode.removeChild(meetingElement);
      }

    }
    this.$store.dispatch('meetingsStore/setMeetingCancelPopupVisible', false);
    this.$store.dispatch('meetingsStore/setMeetingCancel', false);
  }

  public showTime(date: Date): string {
    return DateTimeHelper.getHoursMinutes(date);
  }

}

